<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <Card class="main-content mt-3">
        <template #content>
          <div class="grid">
            <div class="lg:col-8 md:col-12">
              <div class="condition-title">Keyword Term</div>
              <div>
                <AutoComplete
                  v-model="searchState.term"
                  class="w-full"
                  inputClass="w-full"
                  :suggestions="searchTermSuggestions"
                  @complete="searchSuggest($event)"
                />
              </div>
            </div>
            <div class="lg:col-2 md:col-6">
              <div class="condition-title">Start At</div>
              <div>
                <Calendar
                  v-model="searchState.startDate"
                  class="w-full"
                  dateFormat="yy-mm-dd"
                  :minDate="minDate"
                />
              </div>
            </div>
            <div class="lg:col-2 md:col-6">
              <div class="condition-title">End At</div>
              <div>
                <Calendar
                  v-model="searchState.endDate"
                  class="w-full"
                  dateFormat="yy-mm-dd"
                  :minDate="minDate"
                />
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="col-6">
              <span class="text-blue-700">Last Updated Range: </span
              ><span class="text-pink-700 font-semibold">{{
                getLastDateRange()
              }}</span>
              <br />
              <span class="text-blue-700">Next Update before </span
              ><span class="text-pink-700 font-semibold">{{
                getNextThursdayDateString()
              }}</span>
              <span class="text-blue-700">, will update Range: </span
              ><span class="text-pink-700 font-semibold">{{
                getNextDateRange()
              }}</span>
              <p class="text-pink-700 font-semibold">
                Note: The calculation rules for SVR have changed after
                2023-05-14.
              </p>
            </div>
            <div class="col-6">
              <div class="mt-2 text-right">
                <Button
                  class="handleSearch"
                  @click="handleSearch()"
                  :loading="loading"
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </template>
      </Card>
      <div class="grid" v-if="Object.keys(chartData).length">
        <div class="xl:col-9 lg:col-8 md:col-12">
          <Card class="main-content mt-3">
            <template #title> Keyword Trend </template>
            <template #content>
              <Chart
                type="line"
                :data="chartData"
                :options="sftTrendGraphOptions"
              />
            </template>
          </Card>
        </div>
        <div class="xl:col-3 lg:col-4 md:col-12">
          <Card class="main-content mt-3">
            <template #title> Statistics </template>
            <template #subtitle>
              <div v-if="Object.keys(chartInfo).length">
                <div>
                  Top SVR: {{ chartInfo.top[1] }} ({{ chartInfo.top[0] }})
                </div>
                <div>
                  Lowest SVR: {{ chartInfo.lowest[1] }} ({{
                    chartInfo.lowest[0]
                  }})
                </div>
                <div>Variance: {{ chartInfo.variance }}</div>
              </div>
            </template>
            <template #content>
              <Card class="bg-green-100">
                <template #content>
                  <h5 class="text-green-900">Peak season:</h5>
                  <span class="text-green-900">
                    {{ chartInfo.above_average?.join(", ") }}</span
                  >
                </template>
              </Card>
              <Card class="bg-yellow-100">
                <template #content>
                  <h5 class="text-yellow-900">Normal season:</h5>
                  <span class="text-yellow-900">
                    {{ chartInfo.around_average?.join(", ") }}</span
                  >
                </template>
              </Card>
              <Card class="bg-pink-200">
                <template #content>
                  <h5 class="text-pink-900">Slack season:</h5>
                  <span class="text-pink-900">
                    {{ chartInfo.below_average?.join(", ") }}</span
                  >
                </template>
              </Card>
            </template>
          </Card>
        </div>
      </div>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";

import AutoComplete from "primevue/autocomplete";
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Chart from "primevue/chart";

import SearchTermService from "@/services/search_term_service";

import { MakeRegression } from "@/utils/regression";

export default defineComponent({
  components: {
    AutoComplete,
    BlockUI,
    Button,
    Calendar,
    Card,
    Chart,
  },

  setup() {
    onMounted(() => {
      blocked.value = true;
      let nowTimeElapsed = Date.now();
      let now = new Date(nowTimeElapsed);
      let yearAgo = new Date(nowTimeElapsed);
      yearAgo.setFullYear(yearAgo.getFullYear() - 1);

      searchState.endDate = now;
      searchState.startDate = yearAgo;
      blocked.value = false;
    });

    const searchState = reactive({
      term: null,
      startDate: "",
      endDate: "",
    });
    const minDate = ref(new Date("2020-07-19"));
    const blocked = ref(false);
    const loading = ref(false);
    const searchTermSuggestions = ref([]);
    const chartData = ref({});
    const chartInfo = ref({});

    const toast = useToast();

    const handleSearch = () => {
      loading.value = true;
      let data = {
        term: searchState.term.trim(),
        start_date: searchState.startDate.toISOString().substring(0, 10),
        end_date: searchState.endDate.toISOString().substring(0, 10),
      };
      SearchTermService.keyword_term(data)
        .then(({ data }) => {
          if (data.target_array.length == 0) {
            toast.add({
              severity: "warn",
              summary: "Keyword Trend",
              detail: "No Search Term found",
              life: 8000,
            });
            return;
          }
          let aboveAverage = Object.values(data.above_average)[0];
          let average = Object.values(data.average)[0];
          let belowAverage = Object.values(data.below_average)[0];
          chartInfo.value = data.statistics;
          renderTrendGraph(
            data.target_array,
            aboveAverage,
            average,
            belowAverage
          );
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Keyword Trend",
            detail: "Search fail",
            life: 3000,
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const searchSuggest = (event) => {
      let term = { term: event.query.trim() };
      SearchTermService.suggest(term)
        .then(({ data }) => {
          searchTermSuggestions.value = data;
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const renderTrendGraph = (target, aboveAverage, average, belowAverage) => {
      let x = [];
      let y = [];
      target.forEach((element) => {
        let date = new Date(element.label);
        x.push(date.getTime());
        y.push(element.value);
      });
      let regression = MakeRegression(x, y);

      let lineData = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: "#42A5F5",
            borderWidth: 4,
            tension: 0.25,
            order: 0,
            label: "SVR",
          },
          {
            data: [],
            borderColor: "#999999",
            borderWidth: 1,
            borderDash: [10, 10],
            pointStyle: "dash",
            order: 2,
          },
          {
            data: [],
            borderColor: "#999999",
            borderWidth: 1,
            borderDash: [10, 10],
            pointStyle: "dash",
            order: 2,
          },
          {
            data: [],
            borderColor: "#999999",
            borderWidth: 1,
            borderDash: [10, 10],
            pointStyle: "dash",
            order: 2,
          },
          {
            data: [],
            borderColor: "#e66d79",
            borderWidth: 3,
            borderDash: [4, 4, 2],
            pointStyle: "dash",
            order: 1,
            label: "Trendline",
          },
        ],
      };

      let uniqLabel = {};
      target.forEach((element) => {
        if (uniqLabel[element.label]) {
          return;
        }
        uniqLabel[element.label] = true;
        lineData.labels.push(element.label);
        lineData.datasets[0].data.push(element.value);
        lineData.datasets[1].data.push(aboveAverage);
        lineData.datasets[2].data.push(average);
        lineData.datasets[3].data.push(belowAverage);

        let date = new Date(element.label);
        let y = regression.predict(date.getTime());
        lineData.datasets[4].data.push(y);
      });

      chartData.value = lineData;
    };

    const sftTrendGraphOptions = {
      plugins: {
        legend: {
          display: false, // hide the legend
        },
        title: {
          display: true,
          text: "SVR",
          position: "bottom",
        },
      },
      scales: {
        y: {
          reverse: true, // reverse y axis
        },
      },
    };

    const getNextThursday = () => {
      let now = new Date();

      let nextThursday = new Date(
        now.setDate(now.getDate() + ((7 - now.getDay() + 4) % 7 || 7))
      );

      return nextThursday;
    };

    const getNextThursdayDateString = () => {
      return getNextThursday().toLocaleDateString("fr-CA");
    };

    const getNextDateRange = () => {
      let nextThursday = getNextThursday();
      let startDay = new Date(nextThursday - 11 * 86400 * 1000);
      let endDay = new Date(nextThursday - 5 * 86400 * 1000);

      return `${startDay.toLocaleDateString("fr-CA")}
        ~ ${endDay.toLocaleDateString("fr-CA")}`;
    };

    const getLastDateRange = () => {
      let nextThursday = getNextThursday();
      let startDay = new Date(nextThursday - 18 * 86400 * 1000);
      let endDay = new Date(nextThursday - 12 * 86400 * 1000);

      return `${startDay.toLocaleDateString("fr-CA")}
        ~ ${endDay.toLocaleDateString("fr-CA")}`;
    };

    return {
      searchState,
      blocked,
      loading,
      minDate,
      searchTermSuggestions,
      chartData,
      chartInfo,
      getNextThursdayDateString,
      getNextDateRange,
      getLastDateRange,

      handleSearch,
      searchSuggest,
      sftTrendGraphOptions,
    };
  },
});
</script>

<style lang="scss" scoped>
.condition-title {
  font-weight: 500;
}
</style>
